const UrlSetting = {
  //设计器元数据
  design_metadata: dsf.url.getWebPath("rm/list/meta"),
  //SQL数据源
  design_sqlds: dsf.url.getWebPath("rm/list/ds"),
  //SQL数据源
  design_dbsource: dsf.url.getWebPath("dbsource/rmId"),
  //模板
  design_tpl: dsf.url.getWebPath("rm/list/temp"),

  design_ctrl: dsf.url.getWebPath("rm/list/control"),

  //数据字典
  get_dict: dsf.url.getWebPath("rm/list/dataDic"),
  //保存模板
  save_tpl: dsf.url.getWebPath("meta/saveTemp"),
  //初始化
  init: dsf.url.getWebPath("meta/init"),
  //表单加载
  form_load: dsf.url.getWebPath("meta/data"),
  //表单保存
  form_save: dsf.url.getWebPath("meta/persistData"),
  //表单暂存
  form_save_temp: dsf.url.getWebPath("meta/build/transient"),
  //列表请求
  list_load: dsf.url.getWebPath("meta/list/data"),
  //列表保存
  list_count: dsf.url.getWebPath("meta/list/count"),

  // list_load: dsf.url.getWebPath("meta/list/data"),
  // //列表保存
  // list_count: dsf.url.getWebPath("meta/list/count"),

  //列表删除
  list_delete: dsf.url.getWebPath("meta/list/delete"),
  //保存布局
  layout_save: dsf.url.getWebPath("meta/build"),
  //导航树加载
  tree_load: dsf.url.getWebPath("meta/tree"),
  //树异步加载
  tree_load_async: dsf.url.getWebPath("meta/tree/child"),
  //上传excel导入
  uploadExcel: dsf.url.getWebPath("file/upload/excel"),

  //上传base64编码图片
  base64Image: dsf.url.getWebPath("file/base64Img"),

  // 行政区划
  area_load: dsf.url.getWebPath("area/getArea"),
  // 数据字典选项
  dict_list: dsf.url.getWebPath("dict/getList"),
  // 组织架构，部门
  getDeptByOrg: dsf.url.getWebPath("oua/getDeptByOrg"),
  // 组织架构，人员
  getUserByOrg: dsf.url.getWebPath("oua/getUserByOrg"),
  // 组织架构，异步请求
  getUserByDept: dsf.url.getWebPath("oua/getUserByDept"),
  // 本单位，部门
  getDeptByCurUnit: dsf.url.getWebPath("oua/getDeptByCurUnit"),
  // 本单位，人员
  getUserByCurUnit: dsf.url.getWebPath("oua/getUserByCurUnit"),
  // 本部门，人员
  getUserByCurDept: dsf.url.getWebPath("oua/getUserByCurDept"),
  // 元数据获取树
  getTreeByName: dsf.url.getWebPath("meta/tree/byName"),
  // 数据源获取树
  dbSource: dsf.url.getWebPath("meta/tree/dbSource"),
  //获取排课数据
  get_courseData: dsf.url.getWebPath("teas/jwgl/pkgl/pkxx/course/list"),
  //删除排课数据
  delete_courseData: dsf.url.getWebPath("teas/jwgl/pkgl/pkxx/course/pkDelete"),
  //排课发布
  publish_course: dsf.url.getWebPath(
    "teas/jwgl/pkgl/pkxx/course/coursePublish"
  ),
  //确认调课
  sure_adjust: dsf.url.getWebPath("teas/jwgl/pkgl/pkxx/course/switchPublish"),

  // 通过用户id获取用户信息
  getUserById: dsf.url.getWebPath("oua/getUser"),
  // 获取发布过的时间段的所有班级及对应课程表
  getWeekCourseTable: dsf.url.getWebPath(
    "teas/jwgl/pkgl/pkxx/course/queryPublishedCourse"
  ),
  //学员--获取发布过的时间段的所有班级及对应课程表
  studentGetWeekCourseTable: dsf.url.getWebPath(
    "teas/xyxt/xyyzkb/queryPublishedCourse"
  ),
  //获取作业批阅信息
  getHomeWorkMarking: dsf.url.getWebPath(
    "teas/jwgl/zygl/zyfbxy/queryAssignedHomeWork"
  ),
  //保存批阅作业
  saveHomeWorkCorrect: dsf.url.getWebPath(
    "teas/jwgl/zygl/zyfbxy/correctionHomeWork"
  ),
  //作业批阅 批阅完成是提交
  saveAllHomeworkCorrect: dsf.url.getWebPath(
    "teas/jwgl/zygl/zyfbxy/submitHomeWork"
  ),
  //获取年度办班情况
  getClassSituationEcharts: dsf.url.getWebPath("teas/jwgl/index/getBbqk"),
  //获取教师首页年度办办具体信息/
  getClassSituationDetail: dsf.url.getWebPath("teas/jwgl/index/getInfoCount"),
  //获取教师首页当前班级情况
  getCurClassInfo: dsf.url.getWebPath("teas/jwgl/index/queryGoingClass"),
  //获取教师首页 我的班级信息
  getMyClassInfo: dsf.url.getWebPath("teas/jwgl/index/getTeacherClassInfo"),
  //获取教师首页 是否是校领导
  getIsClassLeaderClasses: dsf.url.getWebPath(
    "teas/jwgl/wjdc/checkUserHasRole"
  ),
  //获取教师首页 校领导班级信息
  getClassLeaderClasses: dsf.url.getWebPath(
    "teas/jwgl/index/getSchoolLeaderClassInfo"
  ),
  //获取学员首页 推荐信息
  getRecommend: dsf.url.getWebPath("teas/xyxt/index/getContentInfo"),
  //获取首页待办消息
  getMainInfomationList: dsf.url.getWebPath("teas/cms/main/queryDb"),
  //获取首页代办数量
  getMainInfoTotal: dsf.url.getWebPath("teas/cms/main/queryTodoUnreadNumber"),
  //首页代办消息已读回执
  getMainInfomationListHasread: dsf.url.getWebPath(
    "teas/cms/main/updateStatus"
  ),
  //获取首页 tab的班级共享
  //"getClassShareInfo": dsf.url.getWebPath("teas/xyxt/index/getClassShareInfo"),
  //获取首页 tab的通知公告
  //"getNoticAnnouncement": dsf.url.getWebPath('teas/xyxt/index/getAnnouncement'),
  //获取首页日程安排数据
  getScheduleInfo: dsf.url.getWebPath(
    "dbsource/interface/dsfa/schedule/getList"
  ),
  //获取首页应用数据
  // "getApplicationList": dsf.url.getWebPath("/oua/getPScMenuByRoleID"),
  getApplicationList: dsf.url.getWebPath(
    "teas/portals/menu/getPScMenuByRoleID"
  ),
  //获取我的I-课堂信息
  getMyClassRoom: dsf.url.getWebPath(
    "teas/ikt/iktgl/kcgl/iktListAndYtwtAndDxzl"
  ),
  //获取相关专题
  getReleatedTic: dsf.url.getWebPath("teas/cms/special/specialTopic"),

  getApplicationJson: dsf.url.getWebPath("teas/tempdata/application.json"),

  getuniqTeacher: dsf.url.getWebPath("teas/zygl/szk/queryTeacher"),
  // 快捷栏——已设置
  getPScMenuByUserID: dsf.url.getWebPath("oua/getPScMenuByUserID"),
  // 快捷栏——可设置
  getScMenuByUserID: dsf.url.getWebPath("oua/getScMenuByUserID"),
  // 快捷栏——保存
  savePScMenu: dsf.url.getWebPath("oua/savePScMenu"),

  // 问卷设计页查看是否发布
  getWjdcStatus: dsf.url.getWebPath("dsfa/wjdc/getWjdcStatus"),
  // 问卷发布/取消发布
  changeStatus: dsf.url.getWebPath("dsfa/wjdc/changeStatus"),
  banner: dsf.url.getWebPath("nc/lbt/sellByCount"),
  // nc/lbt/sellByCount
  // 流转信息
  getProcessInfoByPID: dsf.url.getWebPath("wfr/getProcessInfoByPID"),
};

let Action = {
  //设计器元数据
  design_metadata: function (design, nameSpace, callback) {
    let args = {
      namespace: nameSpace,
    };
    let url = UrlSetting.design_metadata;
    dsf.http
      .request(url, args, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  //查询sql数据产生的虚拟元数据
  design_dbsource: function (design, rmid, callback) {
    let args = {
      rmId: rmid,
    };
    let url = UrlSetting.design_dbsource;
    dsf.http
      .request(url, args, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },

  //查询注册的空间
  design_ctrl_sync: function (design, nameSpace, callback) {
    let args = {
      namespace: nameSpace,
    };
    let url = UrlSetting.design_ctrl;
    dsf.http
      .request_sync(url, args, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },

  save_tpl: function (design, callback) {
    design.root.ControlsForEach((ctrl) => {
      if (ctrl.metadata) {
        if (!ctrl.$isFromMetaData && ctrl.metadata_fullcode) {
          delete ctrl.metadata_fullcode;
        }
      }
    });
    let args = {
      layout: "var _tpl=" + design.root.getLayoutJson(),
      pageName: design.root.$name,
      namespace: design.root.nameSpace,
    };
    let url = UrlSetting.save_tpl;
    dsf.http
      .request(url, args, "POST")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  //保存布局
  layout_save: function (design) {},
  //表单初始化
  form_init: function (page, callback) {
    let args = {
      pageName: page.$name,
      namespace: page.nameSpace,
    };
    if (page.nameSpace) {
      let url = UrlSetting.init + (window.location.search || "");
      dsf.http
        .request(url, args, "GET", null, true)
        .done(function (response) {
          if (response.success) {
            callback(null, response.data);
          } else {
            callback(response.data, null);
          }
        })
        .error(function (response) {
          callback(response, null);
        })
        .exec();
    } else {
      callback(null, {});
    }
  },
  //表单保存
  form_save: function (page, callback) {
    let data = page.getPostData();
    let batchId = dsf.url.queryString("batchId");
    data["_id"] = page._data.$$key;
    if (page.$pageType === "question" && batchId) {
      data["batch_id"] = batchId;
    }
    let args = {
      namespace: page.nameSpace,
      pageName: page.$name,
      data: JSON.stringify(data, function (key, val) {
        //$$开头的数据为前端产生的结构，不提交服务器否则会导致服务端
        if (key.indexOf("$$") != 0) {
          return val;
        }
      }),
    };
    if (page.$openTime) {
      args.useTime = Math.ceil((new Date() - page._useTime) / 1000);
    }
    $.extend(true, args, page.addData || {});
    let url = UrlSetting.form_save;
    dsf.http
      .request(url, args, "POST")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data, response);
        } else {
          callback(response.data, response, response);
        }
      })
      .error(function (response) {
        callback(response, null, response);
      })
      .exec();
  },
  //考试系统保存
  exam_save: function (page, callback) {
    let data = page.getPostData();
    data["_id"] = page._data.$$key;
    let args = {
      namespace: page.nameSpace,
      pageName: page.$name,
      data: JSON.stringify(data, function (key, val) {
        //$$开头的数据为前端产生的结构，不提交服务器否则会导致服务端
        if (key.indexOf("$$") != 0) {
          return val;
        }
      }),
    };
    if (page.$openTime) {
      args.useTime = Math.ceil((new Date() - page._useTime) / 1000);
    }
    $.extend(true, args, page.addData || {});
    let url = UrlSetting.form_save + (window.location.search || "");
    dsf.http
      .request(url, args, "POST")
      .done(function (response) {
        if (response.success) {
          callback && callback(null, response.data,response);
        } else {
          callback && callback(response.data, response);
        }
      })
      .error(function (response) {
        callback && callback(response, null);
      })
      .exec();
  },
  //表单加载
  form_load: function (page, callback) {
    var data = {};
    data["_id"] = page._data.$$key;
    var args = {
      data: JSON.stringify(data),
      pageName: page.$name,
      namespace: page.nameSpace,
    };
    var url = UrlSetting.form_load + (window.location.search || "");
    dsf.http
      .request(url, args, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  //列表加载
  list_load: function (list, callback) {
    // 是不是滚动加载
    let isScrollLoad = list._currentShowType == "card" && !list.cardShowPag;
    let args = {
      pageNum: isScrollLoad ? list._cardConfig.curr : list.curr,
      pageSize: !list.showPagination ?
        100 : isScrollLoad ?
        list._cardConfig.pageSize : list.pageSize,
      query: null,
      order: [],
      namespace: list.root.nameSpace,
      pageName: list.root.$name,
    };

    // let orderArr = [];
    // //优先添加自定义排序
    // _.each(list.$customerOrder, function (order) {
    //     if (order.sortType) {
    //         let obj = {
    //             "code": order.field,
    //             "sort": order.sortType
    //         }
    //         orderArr.push(obj);
    //     }
    // });
    // //再添加默认排序
    // _.each(list.$defaultOrder, function (order) {
    //     let field = order.field;
    //     //如果在自定义排序中已经存在，则不添加进排序项目
    //     let cust_ord = _.find(orderArr, (it) => it.code == field);
    //     if (dsf.isUnDef(cust_ord)) {
    //         let obj = {
    //             "code": order.field,
    //             "sort": order.sortType
    //         }
    //         orderArr.push(obj);
    //     }
    // });

    // let search = list.getSearchString();
    // let queryData = {
    //     "searchValue": search.split(/\s+/g).join(" ")
    // };
    // if (list.$searchInfo) {
    //     for (let i = 0; i < list.$searchInfo.length; i++) {
    //         let item = list.$searchInfo[i];
    //         if (item.type == "1") {
    //             queryData[item.code] = item.valueExpress;
    //             // queryData[item.metadata_fullcode] = item.valueExpress;
    //         }
    //     }
    // }
    // let filterData = filterHepler.getSuperFilterData(list._filterItems || []); // getSuperFitler(list);
    // if (filterData) {
    //     $.extend(queryData, filterData);
    // }
    let filterInfo = list.getQueryInfo();
    let url = UrlSetting.list_load + (window.location.search || "");
    args.query = JSON.stringify(filterInfo.query);
    args.order = JSON.stringify(filterInfo.order);
    dsf.http
      .request(url, args, "GET", null, dsf.getCookie('userId') ? false : true)
      // .request(url, args, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data, response);
        } else {
          callback(response, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  //列表数量
  list_count: function (list, callback) {
    // 是不是滚动加载
    let isScrollLoad = list._currentShowType == "card" && !list.cardShowPag;
    let args = {
      pageNum: isScrollLoad ? list._cardConfig.curr : list.curr,
      pageSize: !list.showPagination ?
        100 : isScrollLoad ?
        list._cardConfig.pageSize : list.pageSize,
      query: null,
      namespace: list.root.nameSpace,
      pageName: list.root.$name,
    };
    let filterInfo = list.getQueryInfo();
    let url = UrlSetting.list_count + window.location.search;
    args.query = JSON.stringify(filterInfo.query);
    dsf.http
      .request(url, args, "GET", null, dsf.getCookie('userId') ? false : true)
      // .request(url, args, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  //列表删除
  list_delete: function (list, url, idarray, callback) {
    let args = {
      namespace: list.root.nameSpace,
      pageName: list.root.$name,
      data: JSON.stringify(idarray),
    };
    let questUrl =
      (url ? dsf.url.getWebPath(url) : UrlSetting.list_delete) +
      window.location.search;
    dsf.http
      .request(questUrl, args, "POST")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  //树加载
  tree_load: function (param, callback) {
    /**
     * pid  根节点ID
     * metaFullCode 业务名称_模块名称.组件标示
     * pageName     页面名称不要后缀
     * namespace    业务名称_模块名称
     * globalIdValue    globalId值，因为以前还有个参数globalIdKey所以这个就叫globalIdValue
     * query        查询条件
     * openLevel    异步初始化的时候带上
     */

    let args = {
      pid: param.pid,
      metaFullCode: param.metaFullCode,
      pageName: param.pageName,
      namespace: param.namespace,
      globalIdValue: param.globalIdValue,
      query: null,
      maxLevel: param.maxLevel,
      order: [],
    };

    if (param.openLevel) args.openLevel = param.openLevel;

    let defaultOrder = $.trim(param.$defaultOrder).split(",");
    for (let i = 0; i < defaultOrder.length; i++) {
      let o = defaultOrder[i];
      let reg = /(.+)\s+(desc|asc)/gim;
      let s = $.trim(o);
      if (s) {
        let result = reg.exec(s);
        if (result) {
          let obj = {
            code: result[1], //list.root.scope + "." + result[1],
            sort: result[2] || "ASC",
          };
          args.order.push(obj);
        }
      }
    }

    let queryData = {
      searchValue: "",
    };
    let searchInfo = param.$searchInfo;
    if (searchInfo) {
      for (let i = 0; i < searchInfo.length; i++) {
        let item = searchInfo[i];
        if (item.type == "1") {
          let arr = item.code.split(".");
          if (arr.length == 2) {
            queryData[item.code] = item.valueExpress;
          } else {
            queryData[item.metadata_fullcode] = item.valueExpress;
          }
        }
      }
    }

    let url = UrlSetting.tree_load + window.location.search;
    args.query = JSON.stringify(queryData);

    dsf.http
      .request(url, args, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  //行政区划
  area_load: function (param, callback) {
    let args = {
      TREEINFO_GLOBALID: param.globalid,
      END_LEVEL: param.endI,
      START_LEVEL: param.startI,
    };

    dsf.http
      .request_sync(UrlSetting.area_load, args, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  getDeptByOrg: function (param, callback) {
    // 组织架构，选部门
    dsf.http
      .request(UrlSetting.getDeptByOrg, param, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  getUserByOrg: function (param, callback) {
    // 组织架构，选人
    dsf.http
      .request(UrlSetting.getUserByOrg, {}, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  getDeptByCurUnit: function (param, callback) {
    // 本单位，选部门
    dsf.http
      .request(UrlSetting.getDeptByCurUnit, {}, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  getUserByCurUnit: function (param, callback) {
    // 本单位，选人
    dsf.http
      .request(UrlSetting.getUserByCurUnit, {}, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  getUserByCurDept: function (param, callback) {
    // 本部门，选人
    dsf.http
      .request(UrlSetting.getUserByCurDept, {}, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  getTreeByName: function (param, callback) {
    // 元数据 获取树
    var args = {
      name: param.id,
      pageName: param.pageName,
      namespace: param.namespace,
      order: [],
    };
    if (param.self) {
      $.extend(args, {
        pid: param.self.pId,
        metaFullCode: param.self.metadata_fullcode,
        globalIdValue: param.self.globalId,
        maxLevel: param.self.maxLevel,
        query: null,
      });
      if (param.openLevel) args.openLevel = param.openLevel;

      let defaultOrder = $.trim(param.self.$defaultOrder).split(",");
      for (let i = 0; i < defaultOrder.length; i++) {
        let o = defaultOrder[i];
        let reg = /(.+)\s+(desc|asc)/gim;
        let s = $.trim(o);
        if (s) {
          let result = reg.exec(s);
          if (result) {
            let obj = {
              code: result[1], //list.root.scope + "." + result[1],
              sort: result[2] || "ASC",
            };
            args.order.push(obj);
          }
        }
      }

      let queryData = {
        searchValue: "",
      };
      let searchInfo = param.self.$searchInfo;
      if (searchInfo) {
        for (let i = 0; i < searchInfo.length; i++) {
          let item = searchInfo[i];
          if (item.type == "1") {
            let arr = item.code.split(".");
            if (arr.length == 2) {
              queryData[item.code] = item.valueExpress;
            } else {
              queryData[item.metadata_fullcode] = item.valueExpress;
            }
          }
        }
      }

      args.query = JSON.stringify(queryData);
    }

    dsf.http
      .request(UrlSetting.getTreeByName, args, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  dbSource: function (param, callback) {
    // 数据源 获取树
    var url = UrlSetting.dbSource + window.location.search;
    dsf.http
      .request(
        url, {
          rmId: param.rmId,
        },
        "GET"
      )
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  treeSelector: function (param, callback) {
    dsf.http
      .request(dsf.url.getWebPath(param.url), {}, "GET")
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  getUserByOrg_async: function (param, callback) {
    // 人员加载异步第一次请求
    dsf.http
      .request(
        dsf.url.getWebPath("oua/getUserByOrg"), {
          sExplanLevel: param.sExplanLevel,
        },
        "GET"
      )
      .done(function (response) {
        if (response.success) {
          callback(null, response.data);
        } else {
          callback(response.data, null);
        }
      })
      .error(function (response) {
        callback(response, null);
      })
      .exec();
  },
  getProcessInfoByPID: function (page, callback) {
    let args = {
      sPID: dsf.url.queryString("sPID"),
    };
    if (args.sPID) {
      dsf.http
        .request(UrlSetting.getProcessInfoByPID, args, "GET")
        .done(function (response) {
          if (response.success) {
            callback(null, response.data);
          } else {
            callback(response.data, null);
          }
        })
        .error(function (response) {
          callback(response, null);
        })
        .exec();
    } else {
      callback(null, {});
    }
  },
};

export {
  Action,
  UrlSetting
};