require("./assets/dsf_style.scss");

import "es6-promise/auto";
!(function(global) {
  if (!global.dsf) {
    global.dsf = {
      extend: (namespace, _module) => {
        if (dsf[namespace]) {
          dsf.error("模块名称已经存在");
        }
        dsf[namespace] = _module;
      },
    };
  }

  dsf.JSRely = {
    "dsfa.qi.": "dsfa/qi/hc/qi.js",
    "dsfa.exam.": "dsfa/exam/hc/exam.js",
    "teas.": "teas/hc/teas.js",
    "nc.ncCtrl.": "nc/hc/nc.js",
    "teasAndnc.allCtrl.": "teasAndnc/hc/teasAndnc.js",
    "ct.ctrl": "ct/hc/ct.js",
  };

  const EventEmun = require("./core/ControlEvent");
  for (let k in EventEmun) {
    window[k] = EventEmun[k];
  }
  $(document).ready(function() {
    //加载皮肤
    let theme = dsf.config.defaultTheme;
    //尝试从cookie中读取用户的皮肤设置
    if (dsf.getCookie("theme")) {
      theme = dsf.getCookie("theme");
    }
    $("body").addClass(theme);
    const { express } = require("./core/express");
    const { Action, UrlSetting } = require("./core/pageAction");
    dsf.extend("Action", Action);
    dsf.extend("UrlSetting", UrlSetting);
    dsf.extend("Express", express);
  });
})(window);
window._appInit = function(options) {
  options = options || {};
  let q = dsf.queue();
  q.step(function(def) {
    //检查浏览器是否支持Promise，不支持则引用bluebird.js
    if (!window.Promise) {
      dsf.Import("res/libs/bluebird.js", function() {
        def.resolve();
      });
    } else {
      def.resolve();
    }
  })
    .step(function(def) {
      //加载第三方工具包
      dsf.Import(
        [
          "res/libs/iscroll.js",
          "res/libs/xss.js",
          "res/libs/ejs/ejs.min.js",
          "res/libs/zTree/js/jquery.ztree.all.min.js",
          "res/libs/zTree/js/jquery.ztree.exhide.min.js",
          "res/libs/tagsinput/js/typeahead.bundle.js",
          "res/libs/tagsinput/js/tagsinput.min.js"
        ],
        {
          pos: "body",
          always: function() {
            def.resolve();
          },
        }
      );
    })
    .step(function(def) {
      let files = [];
      if (options.loadFiles) {
        files = options.loadFiles();
      }
      if (dsf.isDef(files)) {
        dsf.Import(files, {
          pos: "body",
          always: function() {
            def.resolve();
          },
        });
        // def.resolve();
      } else {
        def.resolve();
      }
    })
    .step(function(def) {
      try {
        options.ready && options.ready();
        def.resolve();
      } catch (ex) {
        dsf.error(ex);
        def.reject();
      }
    })
    .catch(function() {
      dsf.layer.message("js文件加载出现错误", false);
    })
    .finally(function() {
      $(document).trigger("ds_page_complete");
    })
    .exec();
};
