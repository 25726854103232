export class ControlEvent {
    constructor(ctrl) {
        this.events = {};
        this.ctrl = ctrl;
    }

    top(eventName, callback) {
        createEvent.call(this, eventName);
        this.events[eventName].topHandler.unshift(callback);
    }
    last(eventName, callback) {
        createEvent.call(this, eventName);
        this.events[eventName].lastHandler.push(callback);
    }
    on(eventName, callback) {
        createEvent.call(this, eventName);
        this.events[eventName].handler.push(callback);
    }

    off(eventName, callback) {
        if (this.events[eventName]) {
            let index1 = this.events[eventName].handler.indexOf(callback);
            let index2 = this.events[eventName].topHandler.indexOf(callback);
            let index3 = this.events[eventName].lastHandler.indexOf(callback);
            if (callback) {
                if (index1 >= 0) {
                    this.events[eventName].handler.splice(index1, 1);
                }
                if (index2 >= 0) {
                    this.events[eventName].topHandler.splice(index2, 1);
                }
                if (index3 >= 0) {
                    this.events[eventName].lastHandler.splice(index3, 1);
                }
            } else {
                this.events[eventName].handler = [];
            }

        }
    }

    trigger(eventName, args, q) {
        if (this.events[eventName]) {
            for (let i = 0; i < this.events[eventName].topHandler.length; i++) {
                this.events[eventName].topHandler[i].call(this.ctrl, args, q);
            }
            for (let i = 0; i < this.events[eventName].handler.length; i++) {
                this.events[eventName].handler[i].call(this.ctrl, args, q);
            }
            for (let i = 0; i < this.events[eventName].lastHandler.length; i++) {
                this.events[eventName].lastHandler[i].call(this.ctrl, args, q);
            }
        }
    }

    has(eventName) {
        if (this.events[eventName]) {
            if (this.events[eventName].topHandler.length > 0) {
                return true;
            }
            if (this.events[eventName].handler.length > 0) {
                return true;
            }
            if (this.events[eventName].lastHandler.length > 0) {
                return true;
            }
        }
        return false;
    }
}

function createEvent(eventName) {
    if (!this.events[eventName]) {
        this.events[eventName] = {
            "topHandler": [],
            "handler": [],
            "lastHandler": []
        }
    }
}


let PageEvent = {
    "RENDERBEFORE": "render_before",
    "INIT": "init",
    "INITED": "inited",
    "LOADBEFORE": "load_before",
    "LOADDING": "loadding",
    "LOADED": "loaded",
    "READY": "ready",
    "SAVEBEFORE": 'save_before',
    "SAVEAFTER": 'save_after',
    "SAVECOMPLATE": 'save_complate',
    "BUTTONBARCLICK": "buttonbar_click",
    "POSTDATABEFORE": "postdate_before",
    "VALIDATEERROR": "vaildate_error",
    "CLOSE": "close_window",
    "AUDITSTART": "auditstart",
    "AUDITED": "audited",
    "UPLOADBEFORE": "upload_before",
    "REMOVECONTROL": "remove_control",
    "GetFlowProcess": "flowProcess"
}
let ButtonBar = {
    "BUTTONBARRENDER": "buttonbar_render",
    "COUNTDOWNBRFORE": "countdownbefore",
    "COUNTDOWNSTART": "countdownstart",
    "COUNTDOWNEND": "countdownend",
    "COUNTDOWNENDCL": "countdownendcl",
    "FLOWDESTROY": "flow_destroy",
    "FLOWFORCECLOSE": "flow_forceClose"
}

let CardEvent = {
    "CHECKED": "card_checked"
}

let DataGridEvent = {
    "BUTTONBARCLICK": "buttonbar_click",
    "ROWDBLCLICK": "rowdblclick",
    "BUTTONBARRENDER": "buttonbar_render",
    "ROWBUTTONBARRENDER": "row_buttonbar_render",
    "ROWDELETEBEFORE": "row_delete_before",
    "ROWBUTTONCLICK": "row_buttonbar_click",
    "DATALOADED": "data_loaded",
    "ROWRENDER": "row_render",
    "CHECKED": "checkBox_checked",
    "DOMLOADED": "dom_loaded",
    "LAYPAGECHANGE": "laypage_change",
    "ROWDELETESUCCESS":"row_delete_success"
}

let FormControlEvent = {
    "VALUECHANGE": "value_change",
}

let TextBoxEvent = {
    "INPUT": "textbox_input",
    "BLUR": "text_blur"
}

let AutoCompleteEvent = {
    "SELECT": "autocomplete_select"
}

let SubTableEvent = {
    "LOADED": "subtable_loaded",
    "ADDROW": "subtable_addrow",
    "ADDROWBEFORE": "subtable_addrow_before",
    // "CREATEROW": "subtable_createrow",
    "UPDATEDATA": "subtable_updatedata",
    "DELETEDATA": "subtable_deletedata",
    "IMPORTDATA": "subtable_importdata",
    "DATACHOICE": "subtable_datachoice",
    "BEGINEDIT": "subtable_beginedit",
    "ENDEDIT": "subtable_endedit",
    "DELETEROWBEFORE": "subtable_deleterowbefore",
    "UPLOADBEFORE": "upload_before",
    "VALUECHANGE": "subtable_value_change",
    "CHECKROW": "subtable_check_row"
}

let DataChoiceEvent = {
    "CHOICECOMPLETE": "datachoice_complete",
    "OPENBEFORE": "datachoice_openbefore"
}

let TabEvent = {
    "BADGE": "tab_badge",
    "SELECT": "tab_select"
}


let CourseTableEvent = {
    "PUBLISHCHANGE": "coursetable_publishchange",
    "UPDATEADJUST": "coursetable_updateadjust"
}

let leaveMsgEvent = {
    "COUNTCHANGE": "count_change"
}

let ncModelEvent = {
    'bannerClick': 'bannerEvent'
}

let CustomListEvent = {
    "custom": "custom",
    "tabChange": "tabChange",
    "liClick": "listLiClick",
    "loaded": "loaded"
}
let flowCtrlEvent = {
    "changeState": "flowStateChange",
    "setSendConfig": "setSendConfig"
}
let Menus = {
    "CLICKBEFORE": "click_before",
    "RENDERBEFORE": "render_before"
}
let Fullpage = {
    "AFTERLOAD": "afterload",
    "ONLEAVE": "onleave"
}

let ZYChoice = {
    "DISABLED": "disabled"
}

let CtWorkBenchEvent = {
    YEARCHANGE: 'year_change'
}

let NewsDetailEvent = {
    "DOMLOADED": "dom_loaded"
}
export {
    PageEvent,
    ButtonBar,
    DataGridEvent,
    FormControlEvent,
    TextBoxEvent,
    SubTableEvent,
    CardEvent,
    DataChoiceEvent,
    TabEvent,
    AutoCompleteEvent,
    CourseTableEvent,
    leaveMsgEvent,
    ncModelEvent,
    CustomListEvent,
    flowCtrlEvent,
    Menus,
    ZYChoice,
    Fullpage,
    CtWorkBenchEvent,
    NewsDetailEvent
}
